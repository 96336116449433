import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import tableData from '../../../../mocks/roles.json';
import MlxActionIcon from '../../../../components/common/MlxActionIcon/MlxActionIcon';

function RoleList() {
    const actionTemplate = (rowData: any) => {
        return (
            <div className="d-flex flex-row align-items-center justify-content-end" style={{ minWidth: '70px' }}>
                <MlxActionIcon className="me-3" actionType="EDIT" />
                <MlxActionIcon className="me-3" actionType="DELETE" />
            </div>
        );
    };

    return (
        <DataTable
            className="custom-p-table"
            value={tableData}
            paginator
            rows={10}
            rowsPerPageOptions={[5, 10, 15, 25, 35, 50]}
            size="small"
            removableSort
            scrollable
            scrollHeight="flex"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} items"
            dataKey="id"
        >
            <Column style={{ maxWidth: '12rem', minWidth: '12rem' }} sortable header="Name" field="name"></Column>
            <Column style={{ maxWidth: '12rem', minWidth: '12rem' }} field="area_of_operation" header="Area of Operation"></Column>
            <Column header="" style={{ minWidth: '70px',  background: '#f9fafb' }} alignFrozen="right" frozen={true} body={actionTemplate}></Column>
        </DataTable>
    );
}

export default RoleList;
