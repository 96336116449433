import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import MlxBadge from '../../../../components/common/MlxBadge/MlxBadge';
import { formatDate } from '../../../../utils/moment';
import MlxBlockUI from '../../../../components/common/MlxBlockUI/MlxBlockUI';
import { toTypeCase } from '../../../../utils/common';
import { Button } from 'primereact/button';
import { AxiosResponse } from 'axios';
import { useMutation, UseQueryResult } from 'react-query';
import { _DatatablePageEvent, _Organization, _ToastMessageProps } from '../../../../constants/staticTypes';
import { Dispatch, SetStateAction, useState } from 'react';
import {
    CONFIRM_DELETE_ORGANIZATION_MSG,
    DELETE,
    DELETE_ORGANIZATION,
    DELETE_ORGANIZATION_MSG,
    EDIT,
    EMAIL,
    ITEMS,
    LOADING,
    NO_ITEMS,
    OF,
    ORGANIZATION_NAME,
    PHONE,
    REG_DATE,
    RESEND_INVITE,
    SHOWING,
    STATUS,
    TO,
    VENUE_COUNT,
    WARNING_MSG,
    WEBSITE,
} from '../../../../constants/strings';
import { JTranslation } from '../../../../helpers/jTranslationHelper';
import { useTranslation } from '../../../../hooks/useTranslation';
import { confirmPopup, ConfirmPopup } from 'primereact/confirmpopup';
import HttpServiceHelper from '../../../../helpers/httpServiceHelper';
import { deleteAdminOrganization, resendInvitation } from '../../../../helpers/organizationHelper';
import { AlertVariant } from '../../../../constants/constants';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';

type Props = {
    organizationListQuery: UseQueryResult<AxiosResponse<any, any>, unknown>;
    organizationList: _Organization[];
    dataTableEvent: _DatatablePageEvent;
    setDataTableEvent: Dispatch<SetStateAction<_DatatablePageEvent>>;
    setEditOrganization: Dispatch<SetStateAction<_Organization | undefined>>;
    setShowSideBar: Dispatch<SetStateAction<boolean>>;
    setToastMessage: Dispatch<SetStateAction<_ToastMessageProps>>;
};

const languageTexts = {
    EDIT: EDIT,
    DELETE: DELETE,
    SHOWING: SHOWING,
    TO: TO,
    OF: OF,
    ITEMS: ITEMS,
    LOADING: LOADING,
    NO_ITEMS: NO_ITEMS,
    RESEND_INVITE: RESEND_INVITE,
    WARNING_MSG: WARNING_MSG,
};

function OrganizationList({
    organizationListQuery,
    organizationList,
    dataTableEvent,
    setDataTableEvent,
    setEditOrganization,
    setShowSideBar,
    setToastMessage,
}: Readonly<Props>) {
    const organizationMutation = useMutation(HttpServiceHelper);
    const deleteOrganizationMutation = useMutation(HttpServiceHelper);
    const translatedText = useTranslation(languageTexts, 'capitalize');
    const [organizationId, setOrganizationId] = useState<string | null>(null);

    const onSuccess = (data: any) => {
        setToastMessage({
            message: data?.message ?? '',
            variant: AlertVariant.SUCCESS,
            show: true,
        });
        setOrganizationId(null);
    };

    const onError = (error: string, variant: string) => {
        setToastMessage({
            message: error,
            variant,
            show: true,
        });
        setOrganizationId(null);
    };

    const actionButtonTemplate = (rowData: any) => {
        return (
            <div className="d-flex flex-row align-items-center">
                <Button
                    // tooltip={translatedText.EDIT}
                    // tooltipOptions={{ position: 'left' }}
                    size="large"
                    className="text-success"
                    icon="ri-pencil-line"
                    rounded
                    text
                    aria-label="Filter"
                    onClick={() => {
                        setEditOrganization(rowData);
                        setShowSideBar(true);
                    }}
                    disabled={organizationMutation.isLoading}
                />
                {rowData?.invitationStatus?.toLowerCase() === 'pending' && (
                    <>
                        <Button
                            // tooltip={translatedText.DELETE}
                            // tooltipOptions={{ position: 'left' }}
                            type="button"
                            size="large"
                            className="text-danger"
                            icon="ri-delete-bin-line"
                            rounded
                            text
                            aria-label="Filter"
                            disabled={organizationMutation.isLoading}
                            onClick={(event) => {
                                event.stopPropagation();
                                confirmPopup({
                                    target: event.currentTarget,
                                    message: <JTranslation type="html" text={DELETE_ORGANIZATION_MSG} typeCase="capitalize" />,
                                    icon: 'pi pi-info-circle',
                                    defaultFocus: 'reject',
                                    acceptClassName: 'p-button-danger',
                                    accept: () => onDeleteOrganization(rowData?.id),
                                    reject: () => {},
                                });
                            }}
                        />
                        <Button
                            // tooltip={translatedText.RESEND_INVITE}
                            // tooltipOptions={{ position: 'left' }}
                            size="large"
                            className="text-primary"
                            icon={
                                organizationId === rowData?.id && organizationMutation.isLoading
                                    ? 'pi pi-spin pi-spinner'
                                    : 'ri-send-plane-line'
                            }
                            rounded
                            text
                            aria-label={translatedText.RESEND_INVITE}
                            onClick={(event) => {
                                confirmPopup({
                                    target: event.currentTarget,
                                    message: translatedText.WARNING_MSG,
                                    icon: 'pi pi-exclamation-triangle',
                                    acceptIcon: 'ri-send-plane-line',
                                    acceptLabel: translatedText.RESEND_INVITE,
                                    defaultFocus: 'accept',
                                    accept: () => {
                                        setOrganizationId(rowData?.id);
                                        resendInvitation(organizationMutation, { organizationId: rowData?.id }, onSuccess, onError);
                                    },
                                    reject: () => setOrganizationId(null),
                                });
                            }}
                            disabled={organizationMutation.isLoading}
                        />
                    </>
                )}
            </div>
        );
    };

    const statusTemplate = (rowData: any) => {
        return <MlxBadge value={toTypeCase(rowData?.invitationStatus, 'capitalize')} className="primary" />;
    };

    // Delete permission
    const onDeleteOrganization = (organizationId: string) => {
        confirmDialog({
            header: (
                <div className="d-flex align-items-center">
                    <i className="pi pi-info-circle me-2" style={{ fontSize: '1.35rem' }} />
                    <JTranslation type="html" text={DELETE_ORGANIZATION} typeCase="capitalize" />
                </div>
            ),
            message: <JTranslation type="html" text={CONFIRM_DELETE_ORGANIZATION_MSG} typeCase="capitalize" />,
            draggable: false,
            defaultFocus: 'reject',
            acceptClassName: 'p-button-danger',
            accept: () => {
                deleteAdminOrganization(
                    deleteOrganizationMutation,
                    { id: organizationId },
                    (data: any) => {
                        setToastMessage({
                            message: data?.message ?? '',
                            variant: AlertVariant.SUCCESS,
                            show: true,
                        });
                        organizationListQuery.refetch();
                    },
                    (error: string, variant: string) => {
                        setToastMessage({
                            message: error,
                            variant,
                            show: true,
                        });
                    }
                );
            },
            reject: () => {},
        });
    };

    return (
        <MlxBlockUI isLoading={organizationListQuery.isFetching || deleteOrganizationMutation.isLoading} opacity={0.5}>
            <ConfirmDialog className="mlx-confirm-dialog" />
            <ConfirmPopup className="mlx-confirm-popup" />
            <DataTable
                className="custom-p-table"
                value={organizationList}
                size="small"
                removableSort
                scrollable
                scrollHeight="flex"
                paginatorTemplate={dataTableEvent.paginatorTemplate}
                lazy
                dataKey={'id'}
                paginator
                rowsPerPageOptions={dataTableEvent.rowsPerPageOptions}
                currentPageReportTemplate={`${translatedText.SHOWING} {first} ${translatedText.TO} {last} ${translatedText.OF} {totalRecords} ${translatedText.ITEMS}`}
                rows={dataTableEvent.rows}
                totalRecords={dataTableEvent.totalRows}
                first={dataTableEvent.first}
                onPage={(event) => setDataTableEvent({ ...dataTableEvent, ...event, forceUpdate: dataTableEvent.forceUpdate + 1 })}
                emptyMessage={organizationListQuery.isFetching ? translatedText.LOADING : translatedText.NO_ITEMS}
            >
                <Column
                    style={{ maxWidth: '12rem', minWidth: '12rem' }}
                    field="organizationName"
                    header={<JTranslation text={ORGANIZATION_NAME} typeCase="pascal" />}
                    body={(rowData) => <span>{toTypeCase(rowData?.organizationName ?? '', 'capitalize')}</span>}
                ></Column>
                <Column
                    style={{ maxWidth: '12rem', minWidth: '12rem' }}
                    field="emailId"
                    header={<JTranslation text={EMAIL} typeCase="pascal" />}
                ></Column>
                <Column
                    style={{ maxWidth: '8rem', minWidth: '8rem' }}
                    field="primaryPhone"
                    header={<JTranslation text={PHONE} typeCase="pascal" />}
                ></Column>
                <Column
                    style={{ maxWidth: '8rem', minWidth: '8rem' }}
                    header={<JTranslation text={VENUE_COUNT} typeCase="pascal" />}
                    body={(rowData) => <span>{rowData?.venueCount && +rowData?.venueCount > 0 ? rowData?.venueCount : 'NIL'}</span>}
                ></Column>
                <Column
                    style={{ maxWidth: '12rem', minWidth: '12rem' }}
                    body={(rowData) => {
                        const urlValue =
                            rowData?.website?.startsWith('http') || rowData?.website?.startsWith('https')
                                ? rowData?.website
                                : `https://${rowData?.website}`;

                        let url: any;
                        try {
                            url = new URL(urlValue);
                        } catch (error) {}

                        return url?.host ? <Button label={url?.host} link onClick={() => window.open(url?.href, '_blank')} /> : <></>;
                    }}
                    header={<JTranslation text={WEBSITE} typeCase="pascal" />}
                ></Column>
                <Column
                    style={{ maxWidth: '8rem', minWidth: '8rem' }}
                    header={<JTranslation text={REG_DATE} typeCase="pascal" />}
                    body={(rowData) => formatDate(rowData?.registrationDate)}
                ></Column>
                <Column
                    style={{ maxWidth: '8rem', minWidth: '8rem' }}
                    header={<JTranslation text={STATUS} typeCase="pascal" />}
                    body={(rowData) => statusTemplate(rowData)}
                ></Column>
                <Column style={{ maxWidth: 'auto', minWidth: '8rem', background: '#f9fafb' }} body={(rowData) => actionButtonTemplate(rowData)} header=""  alignFrozen="right" frozen={true}></Column>
            </DataTable>
        </MlxBlockUI>
    );
}

export default OrganizationList;
