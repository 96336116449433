import { useWindowSize } from 'react-use';
import withSidebar from '../../../hoc/withSideBar/withSideBar';
import './Permission.scss';
import { MOBILE_BREAKPOINT } from '../../../constants/constants';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Accordion, AccordionTab } from 'primereact/accordion';
import permissions from '../../../mocks/permissions.json';
import MlxButton from '../../../components/common/MlxButton/MlxButton';
import MlxActionIcon from '../../../components/common/MlxActionIcon/MlxActionIcon';

function Permission() {
    const { width: screenWidth } = useWindowSize();

    return (
        <div className="page-section">
            <span className="page-header">Manage Permissions</span>
            <div className="page-content">
                <div className="content-body">
                    <div className="row py-3 h-100">
                        <div className={`col-md-5 col-lg-4 ${screenWidth < MOBILE_BREAKPOINT ? 'mb-3' : 'h-100'}`}>
                            <div className="permission-body h-100 p-3">
                                <>
                                    <div className="d-flex align-items-center mb-2 py-2">
                                        <div className="flex-grow-1">
                                            <h6 className="d-flex align-items-center card-title mb-0">
                                                <b>Default Permissions</b>
                                            </h6>
                                        </div>
                                        <div className=" flex-grow-0"></div>
                                    </div>
                                    <ul className="roles-link">
                                        <li className="active" role={'button'}>
                                            Administrator
                                        </li>
                                    </ul>
                                </>

                                <>
                                    <div className="d-flex align-items-center mt-5 mb-2 py-2">
                                        <div className="flex-grow-1">
                                            <h6 className="d-flex align-items-center card-title mb-0">
                                                <b>Custom Permissions</b>
                                            </h6>
                                        </div>
                                        <div className=" flex-grow-0">
                                            <MlxButton size="small" outlined label="Add" icon="ri-add-line" iconPos="right" />
                                        </div>
                                    </div>
                                    <ul className="roles-link">
                                        <li className="d-flex flex-row align-items-center justify-content-between" role={'button'}>
                                            <span>Manager</span>
                                            <MlxActionIcon actionType="DELETE" />
                                        </li>
                                        <li className="d-flex flex-row align-items-center justify-content-between" role={'button'}>
                                            <span>Chef</span>
                                            <MlxActionIcon actionType="DELETE" />
                                        </li>
                                        <li className="d-flex flex-row align-items-center justify-content-between" role={'button'}>
                                            <span>Bar Attender</span>
                                            <MlxActionIcon actionType="DELETE" />
                                        </li>
                                    </ul>
                                </>
                            </div>
                        </div>

                        <div className={`col-md-7 col-lg-8 ${screenWidth < MOBILE_BREAKPOINT ? '' : 'h-100'}`}>
                            <div className="permission-body h-100 mb-5 d-flex flex-column position-relative">
                                <div className="p-3 mb-1 d-flex flex-column">
                                    <InputText
                                        className="custom-input-text"
                                        id="username"
                                        aria-describedby="username-help"
                                        value="Administrator"
                                    />
                                </div>
                                <div className="p-3 mb-1 d-flex flex-column">
                                    <InputTextarea rows={4} />
                                </div>
                                <div className="p-3 mt-2">
                                    <Accordion activeIndex={parseInt(permissions[0].permissionKey)}>
                                        {permissions.map((feature, index) => (
                                            <AccordionTab
                                                key={feature.permissionKey}
                                                data-testid={feature.permissionKey + '#permission'}
                                                header={
                                                    <div
                                                        className={
                                                            feature.permissions.length > 0
                                                                ? 'd-flex align-items-center'
                                                                : 'd-flex align-items-center'
                                                        }
                                                    >
                                                        <span className="form-check form-switch ">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                autoComplete="off"
                                                                checked={feature.isActive}
                                                                disabled={feature.isDefault}
                                                            />
                                                        </span>
                                                        <span className="text-black">{feature.permissionName}</span>
                                                    </div>
                                                }
                                                headerClassName={feature.permissions.length > 0 ? '' : 'accordion-no-children'}
                                                contentClassName={feature.permissions.length > 0 ? '' : 'd-none'}
                                            >
                                                {feature.permissions.length > 0 && (
                                                    <div>
                                                        {feature.permissions.map((permission, _index) => (
                                                            <div key={`${permission.permissionKey}`} className="d-flex mb-3">
                                                                <div className="flex-grow-0 ">
                                                                    <div className="">
                                                                        <span className="form-check form-switch">
                                                                            <input
                                                                                className="form-check-input"
                                                                                type="checkbox"
                                                                                autoComplete="off"
                                                                                checked={permission.isActive}
                                                                                disabled={permission.isDefault}
                                                                            />
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div className="flex-grow-1">{permission.permissionName}</div>
                                                                <div className=""></div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            </AccordionTab>
                                        ))}
                                    </Accordion>
                                </div>
                                <div className="permission-footer">
                                    <MlxButton
                                        label="Cancel"
                                        onClick={(event) => {
                                            event.preventDefault();
                                        }}
                                        icon="ri-close-large-line"
                                        iconPos="right"
                                        size="small"
                                        outlined
                                    />
                                    <MlxButton
                                        className="ms-2"
                                        label="Save"
                                        onClick={(event) => {
                                            event.preventDefault();
                                        }}
                                        icon="ri-check-line"
                                        iconPos="right"
                                        size="small"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default withSidebar(Permission);
