import { AlertVariant, MOBILE_BREAKPOINT } from '../../../constants/constants';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Accordion, AccordionTab } from 'primereact/accordion';
import MlxButton from '../../../components/common/MlxButton/MlxButton';
import MlxActionIcon from '../../../components/common/MlxActionIcon/MlxActionIcon';
import { useWindowSize } from 'react-use';
import { QueryClient, useMutation } from 'react-query';
import { ChangeEvent, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from '../../../hooks/useTranslation';
import { _Permission, _PermissionGroup, _ToastMessageProps } from '../../../constants/staticTypes';
import logger from '../../../utils/logger';
import {
    _RolePermissions,
    _UpdatePermissionForm,
    convertToRolePermissions,
    deleteAdminPermission,
    getAdminPermissionWithGroupList,
    onPermissionChange,
    updateAdminPermission,
    updateFullPermissions,
} from '../../../helpers/permissionHelper';
import {
    ADD,
    CANCEL,
    CUSTOM_PERMISSIONS,
    DEFAULT_PERMISSIONS,
    DELETE_PERMISSION_MSG,
    PERMISSION_DESC,
    PERMISSION_NAME,
    SAVE,
} from '../../../constants/strings';
import { useQueryHook } from '../../../hooks/useQueryHook';
import AddAdminPermission from './components/AddAdminPermission';
import { ADMIN_PERMISSION_GROUP_LIST } from '../../../constants/queryKeys';
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import HttpServiceHelper from '../../../helpers/httpServiceHelper';
import { JTranslation } from '../../../helpers/jTranslationHelper';
import './AdminPermission.scss';

const languageTexts = {
    DEFAULT_PERMISSIONS: DEFAULT_PERMISSIONS,
    CUSTOM_PERMISSIONS: CUSTOM_PERMISSIONS,
    ADD: ADD,
    PERMISSION_NAME: PERMISSION_NAME,
    PERMISSION_DESC: PERMISSION_DESC,
    CANCEL: CANCEL,
    SAVE: SAVE,
};

type _AdminPermissionProps = {
    setToastMessage: React.Dispatch<SetStateAction<_ToastMessageProps>>;
};

function AdminPermission({ setToastMessage }: Readonly<_AdminPermissionProps>) {
    const queryClient = new QueryClient();
    const deletePermissionMutation = useMutation(HttpServiceHelper);
    const updatePermissionMutation = useMutation(HttpServiceHelper);
    const { width: screenWidth } = useWindowSize();
    const translatedText = useTranslation(languageTexts, 'pascal');

    const [adminPermissionList, setAdminPermissionList] = useState<_Permission[]>([] as _Permission[]);
    const [adminGroupPermissionList, setAdminGroupPermissionList] = useState<_PermissionGroup[]>([] as _PermissionGroup[]);
    const [activeGroupPermission, setActiveGroupPermission] = useState<_PermissionGroup | null>(null);
    const [activeGroupPermissionList, setActiveGroupPermissionList] = useState<_Permission[] | null>(null);
    const [updatePermissionForm, setUpdatePermissionForm] = useState<_UpdatePermissionForm | null>(null);
    const [addAdminPermissionModel, setAddAdminPermissionModel] = useState<boolean>(false);
    const [resetKey, setResetKey] = useState(0);

    // get admin permission and admin group permission list
    const adminPermissionGroupListQuery = useQueryHook({
        queryKey: ADMIN_PERMISSION_GROUP_LIST,
        queryFn: async () => await getAdminPermissionWithGroupList(queryClient),
        onSuccess: (response: any) => {
            const { adminPermissionListData, adminPermissionGroupListData } = response;

            setAdminGroupPermissionList(adminPermissionGroupListData);
            setAdminPermissionList(adminPermissionListData);

            // set first permission as default, if no permission selected
            if (!activeGroupPermission && adminPermissionGroupListData && adminPermissionGroupListData.length > 0) {
                setActiveGroupPermission(adminPermissionGroupListData[0]);
            }
        },
        onError: (error) => logger.log('Failed to fetch permission list', error),
        refetchOnWindowFocus: false,
        enabled: true,
        enableLoader: true,
    });

    // on permission change
    const onPermissionChangeEvent = (event: ChangeEvent<HTMLInputElement>, permission: _Permission) => {
        const updatedPermissions = onPermissionChange(permission, activeGroupPermissionList as _Permission[], event.target.checked);
        setActiveGroupPermissionList(updatedPermissions ?? []);
    };

    // Delete permission
    const onDeletePermission = (permissionId: string) => {
        deleteAdminPermission(
            deletePermissionMutation,
            { id: permissionId },
            (data: any) => {
                if (activeGroupPermission?.id === permissionId) {
                    setActiveGroupPermission(null);
                }

                setToastMessage({
                    message: data?.message ?? '',
                    variant: AlertVariant.SUCCESS,
                    show: true,
                });
                adminPermissionGroupListQuery.refetch();
            },
            (error: string, variant: string) => {
                setToastMessage({
                    message: error,
                    variant,
                    show: true,
                });
            }
        );
    };

    // on update permission
    const onUpdatePermission = () => {
        if (!updatePermissionForm) return;

        updateAdminPermission(
            updatePermissionMutation,
            updatePermissionForm,
            (data: any) => {
                setToastMessage({
                    message: data?.message ?? '',
                    variant: AlertVariant.SUCCESS,
                    show: true,
                });
                adminPermissionGroupListQuery.refetch();
            },
            (error: string, variant: string) => {
                setToastMessage({
                    message: error,
                    variant,
                    show: true,
                });
            }
        );
    };

    useEffect(() => {
        if (!adminPermissionList || !activeGroupPermission) return;

        const currentActivePermissionGroup = adminGroupPermissionList.find((permission) => permission.id === activeGroupPermission.id);
        if (currentActivePermissionGroup) {
            const updatedPermissionList = updateFullPermissions(adminPermissionList, currentActivePermissionGroup?.permissions ?? []);
            setActiveGroupPermissionList(updatedPermissionList);
        }
    }, [adminGroupPermissionList, adminPermissionList, activeGroupPermission, resetKey]);

    useEffect(() => {
        if (activeGroupPermissionList && activeGroupPermission) {
            const rolePermissions: _RolePermissions[] = convertToRolePermissions(activeGroupPermissionList);
            const updatedPermissionForm: _UpdatePermissionForm = {
                id: activeGroupPermission.id,
                permissionRoleName: activeGroupPermission.permissionRoleName ?? '',
                permissionRoleDescription: activeGroupPermission.permissionRoleDescription ?? '',
                rolePermissions,
            };
            setUpdatePermissionForm(updatedPermissionForm);
        }
    }, [activeGroupPermissionList, activeGroupPermission]);

    return (
        <>
            <ConfirmPopup className="mlx-confirm-popup" />
            <AddAdminPermission
                setToastMessage={setToastMessage}
                visible={addAdminPermissionModel}
                setVisible={setAddAdminPermissionModel}
                setActiveGroupPermission={setActiveGroupPermission}
            />
            <div className="row py-3 h-100">
                <div className={`col-md-5 col-lg-4 ${screenWidth < MOBILE_BREAKPOINT ? 'mb-3' : 'h-100'}`}>
                    <div className="permission-body h-100 p-3">
                        <span>
                            <div className="d-flex align-items-center mb-2 py-2">
                                <div className="flex-grow-1">
                                    <h6 className="d-flex align-items-center card-title mb-0">
                                        <b>{translatedText?.DEFAULT_PERMISSIONS}</b>
                                    </h6>
                                </div>
                                <div className=" flex-grow-0"></div>
                            </div>
                            <ul className="roles-link">
                                {adminGroupPermissionList?.map((permissionGroup) => {
                                    if (!permissionGroup?.isDefault) return null;

                                    return (
                                        <li
                                            key={permissionGroup?.id}
                                            className={permissionGroup?.id === activeGroupPermission?.id ? 'active' : ''}
                                            role="button"
                                            onClick={(e) => {
                                                e.preventDefault();

                                                if (permissionGroup?.id === activeGroupPermission?.id) return;

                                                setActiveGroupPermission(permissionGroup);
                                            }}
                                        >
                                            {permissionGroup?.permissionRoleName}
                                        </li>
                                    );
                                })}
                            </ul>
                        </span>

                        <span>
                            <div className="d-flex align-items-center mt-5 mb-2 py-2">
                                <div className="flex-grow-1">
                                    <h6 className="d-flex align-items-center card-title mb-0">
                                        <b>{translatedText?.CUSTOM_PERMISSIONS}</b>
                                    </h6>
                                </div>
                                <div className=" flex-grow-0">
                                    <MlxButton
                                        size="small"
                                        outlined
                                        label={translatedText?.ADD}
                                        icon="ri-add-line"
                                        iconPos="right"
                                        onClick={() => setAddAdminPermissionModel(true)}
                                    />
                                </div>
                            </div>
                            <ul className="roles-link">
                                {adminGroupPermissionList?.map((permissionGroup) => {
                                    if (permissionGroup?.isDefault) return null;

                                    return (
                                        <li
                                            key={permissionGroup?.id}
                                            className={`d-flex flex-row align-items-center position-relative ${
                                                permissionGroup?.id === activeGroupPermission?.id ? 'active' : ''
                                            }`}
                                            role="button"
                                            onClick={(e) => {
                                                e.preventDefault();

                                                if (permissionGroup?.id === activeGroupPermission?.id) return;

                                                setActiveGroupPermission(permissionGroup);
                                            }}
                                        >
                                            <span>{permissionGroup?.permissionRoleName ?? ''}</span>
                                            <MlxActionIcon
                                                className={`position-absolute ${
                                                    permissionGroup.id === activeGroupPermission?.id ? 'text-light' : ''
                                                }`}
                                                actionType="DELETE"
                                                role="button"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    confirmPopup({
                                                        target: e.currentTarget,
                                                        message: (
                                                            <JTranslation type="html" text={DELETE_PERMISSION_MSG} typeCase="capitalize" />
                                                        ),
                                                        icon: 'pi pi-info-circle',
                                                        defaultFocus: 'reject',
                                                        acceptClassName: 'p-button-danger',
                                                        accept: () => onDeletePermission(permissionGroup?.id),
                                                        reject: () => {},
                                                    });
                                                }}
                                                style={{ right: '0.5rem', zIndex: '1' }}
                                            />
                                        </li>
                                    );
                                })}
                            </ul>
                        </span>
                    </div>
                </div>

                <div className={`col-md-7 col-lg-8 ${screenWidth < MOBILE_BREAKPOINT ? '' : 'h-100'}`}>
                    <div className="permission-body h-100 mb-5 d-flex flex-column position-relative">
                        <div className="p-3 mb-1 d-flex flex-column">
                            <InputText
                                className="custom-input-text"
                                id="username"
                                aria-describedby="username-help"
                                value={activeGroupPermission?.permissionRoleName ?? ''}
                                placeholder={translatedText.PERMISSION_NAME}
                                onChange={(e) => {
                                    setActiveGroupPermission((activeGroupPermission) => {
                                        if (!activeGroupPermission) return null;
                                        return { ...activeGroupPermission, permissionRoleName: e.target.value };
                                    });
                                }}
                            />
                        </div>
                        <div className="p-3 mb-1 d-flex flex-column">
                            <InputTextarea
                                rows={4}
                                value={activeGroupPermission?.permissionRoleDescription ?? ''}
                                placeholder={translatedText.PERMISSION_DESC}
                                onChange={(e) => {
                                    setActiveGroupPermission((activeGroupPermission) => {
                                        if (!activeGroupPermission) return null;
                                        return { ...activeGroupPermission, permissionRoleDescription: e.target.value };
                                    });
                                }}
                            />
                        </div>
                        <div className="p-3 mt-2">
                            <Accordion multiple={true}>
                                {activeGroupPermissionList?.map((feature, index) => (
                                    <AccordionTab
                                        key={feature?.permissionKey}
                                        data-testid={feature?.permissionKey + '#permission'}
                                        header={
                                            <div className={`d-flex align-items-center permission-accordion-tab`}>
                                                <span className="form-check form-switch ">
                                                    <input
                                                        key={feature?.permissionKey}
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        autoComplete="off"
                                                        checked={feature?.isActive}
                                                        // disabled={feature?.isDefault}
                                                        onClick={(e) => e.stopPropagation()}
                                                        onChange={(e) => onPermissionChangeEvent(e, feature)}
                                                    />
                                                </span>
                                                <span className="permission-name text-black">{feature?.permissionName}</span>
                                            </div>
                                        }
                                        headerClassName={feature?.subPermissions.length > 0 ? '' : 'accordion-no-children'}
                                        contentClassName={feature?.subPermissions.length > 0 ? '' : 'd-none'}
                                    >
                                        {feature?.subPermissions.length > 0 && (
                                            <div>
                                                {feature?.subPermissions.map((permission, _index) => (
                                                    <div key={`${permission?.permissionKey}`} className="d-flex mb-3">
                                                        <div className="flex-grow-0 ">
                                                            <div className="">
                                                                <span className="form-check form-switch">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="checkbox"
                                                                        autoComplete="off"
                                                                        checked={permission?.isActive}
                                                                        // disabled={permission?.isDefault}
                                                                        onChange={(e) => onPermissionChangeEvent(e, permission)}
                                                                    />
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="flex-grow-1">{permission.permissionName}</div>
                                                        <div className=""></div>
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </AccordionTab>
                                ))}
                            </Accordion>
                        </div>
                        <div className="permission-footer">
                            <MlxButton
                                label={translatedText.CANCEL}
                                onClick={(event) => {
                                    event.preventDefault();
                                    setResetKey((prev) => prev + 1);
                                }}
                                icon="ri-close-large-line"
                                iconPos="right"
                                size="small"
                                outlined
                            />
                            <MlxButton
                                className="ms-2"
                                label={translatedText.SAVE}
                                onClick={(event) => {
                                    event.preventDefault();
                                    onUpdatePermission();
                                }}
                                icon="ri-check-line"
                                iconPos="right"
                                size="small"
                                disabled={updatePermissionMutation?.isLoading}
                                loading={updatePermissionMutation?.isLoading}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AdminPermission;
