// login
export const USERNAME = 'Username';
export const PASSWORD = 'Password';
export const FORGOT_PASSWORD = 'Forgot Password';
export const FOOTER_BRAND = 'Inc. All rights reserved';
export const LOGIN = 'Login';
export const REMEMBER_ME = 'Remember me';
export const INACTIVE_USER_ERROR = 'Your account has been marked as inactive. Reach out to our admin team to reactivate your account.';
export const LOGIN_ERROR = 'Invalid credentials. Please try again.';
export const UNKNOWN_ERROR = 'Something went wrong. Please try again.';

// header
export const SETTINGS = 'Settings';
export const LOGOUT = 'Logout';
export const SUPER_ADMIN = 'Super admin';
export const ADMIN_STAFF = 'Admin staff';
export const ORG_ADMIN = 'Org admin';
export const ORG_STAFF = 'Org staff';

// Organization
export const MANAGE_ORGANIZATION = 'Manage Organization';
export const ORG_LIST = 'Organization List';
export const INVITE_ORGANIZATION = 'Invite Organization';
export const ORGANIZATION_NAME = 'Organization Name';
export const EMAIL = 'Email';
export const PHONE = 'Phone';
export const WEBSITE = 'Website';
export const REG_DATE = 'Registration Date';
export const CONTACT_NAME = 'Contact Name';
export const PRIMARY_PHONE = 'Primary Phone';
export const SECONDARY_PHONE = 'Secondary Phone';
export const CONTACT_ADDRESS = 'Contact Address';
export const ZIP = 'Zip';
export const CITY = 'City';
export const STATE = 'State';
export const COUNTRY = 'Country';
export const SEND_INVITE = 'Send Invite';
export const FIRST_NAME = 'First Name';
export const LAST_NAME = 'Last Name';
export const CONFIRM_PASSWORD = 'Confirm Password';
export const GET_STARTED = 'Get Started';
export const USERNAME_TAKEN_MSG = 'This username already taken';
export const VENUE_COUNT = 'Venue Count';
export const VENUE_ADDRESS = 'Venue Address';
export const DELETE_ORGANIZATION = 'Delete Organization';
export const DELETE_ORGANIZATION_MSG = 'Do you want to delete this organization?';
export const CONFIRM_DELETE_ORGANIZATION_MSG =
    'You cannot revert this action. This will permanently delete the organization and all the associated venues and users.';

// on-boarding
export const STEP_2 = 'Step 2 of 4';
export const CREATE_FIRST_AVENUE = `Let's create your first Venue`;
export const CREATE_FIRST_AVENUE_MSG = 'Please provide the essential information about the venue and primary contact.';
export const CREATE_SECOND_AVENUE_MSG = 'Upload the logo that will be displayed on web/mobile applications.';
export const CREATE_THIRD_AVENUE_MSG = "Enter the desired URL for your venue. This will be used to create your venue's personalized link.";
export const WHAT_FIRST_AVENUE = 'What is the name of the venue';
export const CHECKING_DOMAIN_AVAILABILITY = 'Checking domain availability...';
export const CREATE_FIRST_AVENUE_WARNING = 'Once you set the URL name, it cannot be changed.';
export const PREFERRED_VENUE_URL = 'Preferred venue URL';
export const STEP_3 = 'Step 3 of 4';
export const STEP_4 = 'Step 4 of 4';
export const UPLOAD_WEB_LOGO = 'Upload Web Logo';
export const UPLOAD_MOBILE_LOGO = 'Upload Mobile Logo';
export const UPLOAD_DIMENSION_MSG = 'Recommended dimensions';

// Common
export const EDIT = 'Edit';
export const DELETE = 'Delete';
export const STATUS = 'Status';
export const SEARCH = 'Search';
export const ADD = 'Add';
export const UPDATE = 'Update';
export const CANCEL = 'Cancel';
export const SHOWING = 'Showing';
export const TO = 'to';
export const OF = 'of';
export const ITEMS = 'items';
export const LOADING = 'Loading...';
export const NO_ITEMS = 'No Items';
export const COMING_SOON = 'Coming Soon';
export const RESEND_INVITE = 'Resend Invitation';
export const WARNING_MSG = 'Are you sure you want to proceed?';
export const CONTINUE = 'Continue';
export const BACK = 'Back';
export const NAME = 'Name';
export const FINISH = 'Finish';
export const PASSWORD_CHECK_LOWERCASE = 'At least one lowercase';
export const PASSWORD_CHECK_UPPERCASE = 'At least one uppercase';
export const PASSWORD_CHECK_NUMBER = 'At least one number';
export const PASSWORD_CHECK_SPECIAL = 'At least one special character';
export const PASSWORD_CHECK_MIN = 'Minimum 8 characters';
export const SAVE = 'Save';

// Google maps
export const SEARCH_PLACE = 'Search for a place';

// File upload
export const UPLOAD_PHOTO_TYPE_ERROR = 'The selected file is not an image. Please try again.';
export const UPLOAD_VIDEO_TYPE_ERROR = 'The selected file is not a video. Please try again.';
export const USER_UPLOAD_PHOTO_ERROR = 'Error uploading photo.';
export const UPLOAD_PHOTO_INFO = 'Please upload images larger than TXT*TXTpx (Width * Height).';
export const UPLOAD_PHOTO_ERROR = 'The selected image is too small. It must be at least TXT*TXTpx (Width * Height).';

// Permissions
export const DEFAULT_PERMISSIONS = 'Default Permissions';
export const CUSTOM_PERMISSIONS = 'Custom Permissions';
export const ORG_PERMISSIONS = 'Org Permissions';
export const PERMISSION_NAME = 'Permission Name';
export const PERMISSION_DESC = 'Permission Description';
export const ADD_PERMISSION = 'Add Permission';
export const DELETE_PERMISSION_MSG = 'Do you want to delete this permission?';
